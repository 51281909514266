// Settings for authentication context - will override default settings
let baseConfig = {
  authToken: 'Bearer:',
  baseUrl: 'https://uat.i-alert.cn/api/v1/',
  springBaseUrl: 'https://uat.i-alert.cn/api/v2/',
  frontEndBaseUrl: 'https://uat.i-alert.cn/',
  frontEndSpaBaseUrl: 'https://uat.i-alert.cn/assets/single-spa-aurelia/',
  subFolder: '',
  loginOnSignup: false,
  loginRedirect: false,
  loginRoute: '/v2#/login',
  loginUrl: 'login',
  responseTokenProp: 'data',
  signupRedirect: '#/',
  signupRoute: '#/',
  signupUrl: 'signup',
  tokenName: 'login_token',
  tokenPrefix: 'portal',
  underMaintenance: false
};

export default baseConfig;
